 // Disable Gotham for Vietnamese (vi-VN) as it only supports a subset of Vietnamese glyphs
 // leading to a mix of fonts within a string
html[lang="vi-VN"] * {
    font-family: Noto Sans, sans-serif;
}

 $gotham: Gotham A, Gotham B, Noto Sans, Noto Sans Thai Looped, Noto Sans TC, Noto Sans SC, Noto Sans JP, Noto Sans Arabic, sans-serif;

/* Used in legacy Atoms Label & Button components */
.gotham {
  font-family: $gotham;
}

/* This is imported into fonts.ts and then refrenced in legacy styled components using ${FontFamily.gotham} */
 :export {
   gotham: $gotham;
 }
