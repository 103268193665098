@import "../../node_modules/@carrot-fertility/tachyons";
@import "variables/breakpoints";
@import "variables/colors";

@import "fonts";
@import "colors.scss";
@import "form.css";
@import "calendly.scss";

/**
* Note: The order in which these classes are placed in this file matters for precedence to work properly,
*       All classes for Not-Small(-ns) should be placed after the classes for small layout
*/

body {
  block-size: initial !important;
  font-family: $gotham;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
}
}

/* Used in Atoms TextLink */
.cc {
  color: currentColor;
}

/* Used in legacy/Styled inputs */
input::placeholder {
  color: #00000066;
}

/* Used in legacy Modal */
.shadow-modal {
  box-shadow: 0 15px 35px rgba(58, 75, 82, 0.25);
}

/* Used in Atoms Button */
.pointer-events {
  pointer-events: auto;
}

/* Used in Atoms Button and Styled Inputs */
.not-allowed {
  cursor: not-allowed;
}

/* Used in RRNavLink */
.min-w150px {
  min-inline-size: 150px;
}

/* Used in Wrapper */
.mw65 {
  max-inline-size: 40rem;
}

/* Used in Wrapper */
.mw75 {
  max-inline-size: 56rem;
}

/* Used in Wrapper */
.mw85 {
  max-inline-size: 80rem;
}

/* Used in ActionModal */
.mw-1280 {
  max-inline-size: 100vi;
}

@media screen and (min-width: 1280px) {
  /* Used in ActionModal */
  .mw-1280 {
    max-inline-size: 1280px;
  }
}

/* Used in legacy Modal */
.vw-100 {
  inline-size: 100vi;
}

/* Used in UpdateBankInfoMOdal */
.mv35 {
  margin-block-start: 1.5rem;
  margin-block-end: 1.5rem;
}

/* Used in UpdateBankInfoModal */
.mt35 {
  margin-block-start: 1.5rem;
}

@media #{$breakpoint-small} {
  /* Used in UploadDropzone, legacy Modal, Wrapper, and UpdateBankInfoModal */
  .ph3-s {
    padding-inline-start: 1rem;
    padding-inline-end: 1rem;
  }
}

/* Used in UpdateBankInfoModal */
@media #{$breakpoint-large} {
  .mt25-l {
    margin-block-start: 2.5rem;
  }
}

/* Used in legacy Modal and RoundedRectangle */
.pa35 {
  padding: 1.5rem;
}

@media #{$breakpoint-large} {
  /* Used in legacy Modal */
  .pa55-l {
    padding: 6rem;
  }
}

@media #{$breakpoint-small} {
  /* Used in UpdateBankInfoModal */
  .ph35-s {
    padding-inline-start: 1.5rem;
    padding-inline-end: 1.5rem;
  }
}

/* Used in UploadDropzoneWithIllustration */
.pv35 {
  padding-block-start: 1.5rem;
  padding-block-end: 1.5rem;
}

/* Used in BankDetailsPage, Attachments.tsx, Toggles.tsx, UnknownErrorOccurred, PageNotFound, and legacy RoundedRectange, TextLink, ArrowLink, and Button */
.animate-all {
  transition: all 0.15s cubic-bezier(0.8, 0, 0.2, 1);
}

/* Used in UploadDropzoneWithIllustration */
.animate-all-slow {
  transition: all 0.3s cubic-bezier(0.8, 0, 0.2, 1);
}

/* Used in PlayerIframe */
/* video embed styles */
.embed-container {
  position: relative;
  padding-block-end: 56.25%;
  block-size: 0;
  overflow: hidden;
  max-inline-size: 100%;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
  inline-size: 100%;
  block-size: 100%;
}

/* Used in TalkToCarrotLocationPicker */
.ml-auto {
  margin-inline-start: auto;
}

/* Used in UploadDropzoneWithIllustration */
.fullscreen-dropzone-overlay {
  position: fixed;
  inset-block-start: 0;
  inset-block-end: 0;
  inset-inline-start: 0;
  inset-inline-end: 0;
  z-index: 99999999;
  opacity: 0;
}

/* Used in RRNavLink */
.w-8px {
  inline-size: 8px;
}

/* Used in RRNavLink */
.h-2px {
  block-size: 2px;
}

@media #{$breakpoint-large} {
  /* Used in BodyWithLeftSidebarLayout and DigitalBenefitsGuideDesktopNav */
  .w-220px-l {
    min-inline-size: 220px;
    inline-size: 220px;
  }
}

@media #{$breakpoint-medium} {
  /* Used in UpdateBankInfoModal */
  .mw-600-m {
    max-inline-size: 600px;
  }
}

/* Used in Modal and UpdateBankInfoModal */
.mw-600 {
  max-inline-size: 600px;
}

@media #{$breakpoint-large} {
  /* Used in UpdateBankInfoModal */
  .mw-968-l {
    max-inline-size: 968px;
  }
}

@media #{$breakpoint-medium} {
  /* Used in UpdateBankInfoModal */
  .mw-600-m {
    max-inline-size: 600px;
  }
}