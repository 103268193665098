// Colors
$bright-green: #29bc7e;
$brownish-gray: #d1c2b5;
$carrot-blue: #4459a8;
$carrot-green: #779e91;
$carrot-orange: #e48400;
$carrot-red: #a33e35;
$coral: #eba378;
$dark-blue: #15415b;
$dark-carrot-green: #6b8e82;
$dark-carrot-orange: #c97500;
$dark-fiord: #364d5c;
$dark-mint: #347559;
$dark-old-rose: #9a4856;
$dark-pampas: #e6e1d9;
$dark-warm-gray: #e9d4c1;
$deep-carrot-orange: #f78500;
$fiord: #3c5566;
$geyser: #d9e1e6;
$gray: #777;
$green-white: #e8ede4;
$icon-grey: #dae1e5;
$invalid-red: #d26556;
$light-carrot-green: #d7e2de;
$light-carrot-orange: #fbd7b1;
$light-gray: #f5f5f5;
$light-pampas: #faf9f7;
$linen: #f9e7e1;
$mineral-grey: #d9d9d9;
$mint-alternate: #ddebe2;
$mint: #c2dbcc;
$mystic: #d7e1e7;
$old-rose: #c57a83;
$pampas: #f4f2ef;
$pampas-50-opacity: #faf9f7;
$pattens-blue: #d3dfe3;
$pearl-lusta: #fcf6e6;
$puce: #c78590;
$red-orange-white: #faf0eb;
$roman-red: #d26556;
$sea-peach: #f4ecee;
$shuttle-gray: #63656a;
$spring-wood: #f5f3eb;
$strong-cyan-blue: #2989bc;
$very-light-carrot-orange: #f9ead5;
$fair-pink: #f4e8dc;
$fair-pink-50-opacity: #f9f3ed;
$warm-gray: #eeddcd;
$ghost-white: #f9f9fb;
$chardon: #f5eae0;
$sea-grey: #cccccc;
$silver: #c9c9c9;
$cornflower-blue: #dae1e5;
$light-blue: #e0e8ea;
$nurse-gray: #e6ebe8;
$porcelain: #e4e8eb;
$devil-gray: #666;
$white: #ffffff;
$old-lace: #fef9f2;

:export {
  chardon: $chardon;
  mineralGrey: $mineral-grey;
  ghostWhite: $ghost-white;
  invalidRed: $invalid-red;
  seaGrey: $sea-grey;
  pampas: $pampas;
  pampas50Opacity: $pampas-50-opacity;
  silver: $silver;
  carrotOrange: $carrot-orange;
  white: $white;
  darkBlue: $dark-blue;
  darkWarmGray: $dark-warm-gray;
  lightBlue: $light-blue;
  fairPink: $fair-pink;
  fairPink50Opacity: $fair-pink-50-opacity;
  lightGray: $light-gray;
  mint: $mint;
  mintAlternate: $mint-alternate;
}
