/* Used in AppAccessPageCard */
@keyframes shake {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  12.5% {
    -webkit-transform: translateX(-6px);
    transform: translateX(-6px);
  }

  37.5% {
    -webkit-transform: translateX(5px);
    transform: translateX(5px);
  }

  62.5% {
    -webkit-transform: translateX(-3px);
    transform: translateX(-3px);
  }

  87.5% {
    -webkit-transform: translateX(2px);
    transform: translateX(2px);
  }

  to {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

/* Used in AppAccessPageCard */
.shake {
  animation-duration: 0.4s;
  animation-timing-function: ease-in-out;
  animation-name: shake;
}

/* Used in UploadDropzoneWithIllustration */
.no-outline {
  outline: none;
}

/* Used in legacy Atoms Form component */
.invisible {
  visibility: hidden;
  inline-size: 0;
  block-size: 0;
  overflow: hidden;
}