/* used by FadeTransition component */
.fade-appear {
  opacity: 0;
}
.fade-appear.fade-appear-active {
  opacity: 1;
  transition: all 0.6s linear;
}
.fade-enter {
  opacity: 0;
}
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: all 0.6s linear 0.4s;
}
.fade-leave {
  opacity: 1;
}
.fade-leave.fade-leave-active {
  opacity: 0;
  position: absolute;
  inline-size: 100%;
  transition: all 0.2s linear;
}
